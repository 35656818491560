<template>
    <v-card flat>
        <v-card-text>
            <v-data-iterator :items="fullQueryData.result_urls">
                <template v-slot:header>
                    <div class="d-flex align-center justify-end">
                        <GetTableData class="mr-1" :sqlText="fullQueryData.command" />
                        <v-dialog v-model="sqlDialog" max-width="500">
                            <template v-slot:activator="{ on }">
                                <v-btn text v-on="on">
                                    <v-icon color="secondary" class="mr-1" small dark>mdi-eye-outline</v-icon>
                                    <span class="font-weight-bold caption secondary--text">View Query</span>
                                </v-btn>
                            </template>
                            <v-card>
                                <v-card-title>
                                    <div class="secondary--text">
                                        <v-icon class="mr-1">mdi-script-text-outline</v-icon>
                                        SQL Text
                                    </div>
                                </v-card-title>
                                <v-divider class="mb-1"></v-divider>
                                <v-card-text>
                                    <CodeHighlight language="sql" :code="fullQueryData.command" />
                                </v-card-text>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="secondary" text @click="sqlDialog = false">close</v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </div>
                </template>
                <template v-slot:default="props">
                    <v-list two-line>
                        <div v-for="(item, index) in props.items" :key="index">
                            <v-list-item two-line>
                                <v-list-item-avatar>
                                    <v-icon large>mdi-folder-zip</v-icon>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <a :href="item" color="primary" v-on="on" style="text-decoration: none" class="subtitle-2 secondary--text">
                                                    {{ item.split('/').pop() }}
                                                </a>
                                            </template>
                                            <span>Download</span>
                                        </v-tooltip>
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <v-btn
                                                    color="secondary"
                                                    class="ml-2"
                                                    href="https://docs.nuvolos.cloud/features/database-integration/view-tables#extracting-results"
                                                    target="_blank"
                                                    v-on="on"
                                                    rounded
                                                    text
                                                    outlined
                                                    x-small
                                                    e2e-queries-compressed-file>
                                                    <div class="d-flex align-center">
                                                        <v-icon class="mr-1" small color="secondary">info</v-icon>
                                                        Compressed File
                                                    </div>
                                                </v-btn>
                                            </template>
                                            <span>This is a compressed file, click here for instructions to extract it</span>
                                        </v-tooltip>
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-divider inset></v-divider>
                        </div>
                    </v-list>
                </template>
            </v-data-iterator>
        </v-card-text>
    </v-card>
</template>

<script>
import { mapState } from 'vuex'
const CodeHighlight = () => import('@/components/CodeHighlight')
const GetTableData = () => import('../components/TheSnapshotTableGetData')
export default {
    name: 'QueryFiles',
    components: { CodeHighlight, GetTableData },
    data() {
        return {
            sqlDialog: false
        }
    },
    computed: {
        ...mapState('snapshotStore', ['fullQueryData'])
    }
}
</script>
